<template>
  <div>
    <el-dialog :visible.sync="sta.show">
      <span slot="title">新增/修改 分类</span>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label><b>名称</b></label>
            <el-input type="text" v-model="model.NAME" />
          </div>
          <div class="form-group">
            <label><b>仓库</b></label>
            <el-select v-model="model.STORAGE_ID" placeholder="请选择" :disabled="isStorage">
              <el-option v-for="item in storages" :key="item.ID" :label="item.STORAGE_NAME" :value="item.ID">
              </el-option>
            </el-select>
          </div>
          <div class="form-group">
            <label><b>排序号</b></label>
            <el-input type="text" v-model="model.SORT" />
          </div>
        </div>
      </div>
      <div slot="footer" v-loading="sta.loading">
        <el-button icon="el-icon-close" @click="sta.show=false">取消</el-button>
        <el-button icon="el-icon-check" type="success" @click="submit">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        sta: {
          show: false,
          loading: false
        },
        storages: [],
        isStorage: false,
        model: { }
      }
    },
    methods: {
      init(method, model) {
        this.sta = { show: true, loading: false }
        if (method == 1) {//增加根分类
          this.isStorage = false;
          this.model = {
            STORAGE_ID: ""
          };
        }
        else if (method == 2) { //增加下级分类
          this.model = {
            PARENT_ID: model.ID,
            STORAGE_ID: model.STORAGE_ID
          };
          if (model.STORAGE_ID > 0)
            this.isStorage = true;
          else
            this.isStorage = false;
        }
        else {//修改分类
          this.model = JSON.parse((JSON.stringify(model)));
          if (model.ID > 0)
            this.isStorage = !(model.PARENT_ID == 0 || model.PARENT_ID == null)
        }
        this.getStorage();
      },
      submit() {
        let self = this;
        self.sta.loading = true;
        this.whale.remote.getResult({
          url: "/api/School/MAT/MCatApi/Save",
          data: self.model,
          finally() { self.sta.loading = false; },
          completed: function () {
            self.sta.show = false;
            self.$emit("on-saved");
          }
        })
      },
      getStorage() {
        let self = this;
        self.whale.remote.getResult({
          url: "/api/School/MAT/MatStorage/GetList",
          completed: function (ret) {
            self.storages = ret.DATA;
          }
        })
      }
    }
  }
</script>

<style scoped>
  .el-select {
    width: 100%;
  }
</style>